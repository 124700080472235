import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { StaticImage } from 'gatsby-plugin-image'
import BodyItem from './body-item';

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  width: "300px",
  textAlign: 'left',
  flexGrow: 1
}));


export default function HeaderGrid() {
  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        sx={{ flexWrap: 'wrap' }}
      >
        <Item>
          <StaticImage src={'../../images/nesisai-productity-333x500.png'}  alt='' />
        </Item>
        <Item>
          <Typography variant="h4" gutterBottom><strong>Maximize Your Productivity</strong></Typography>
          <Typography variant="h6">
            By automating information retrieval and analysis, NesisAI slashes the time and effort required to find and interpret critical data.
            Imagine your employees making faster, smarter decisions—unlocking the full potential of your business.
          </Typography>
        </Item>
      </Stack>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="col"
        useFlexGap
        sx={{ flexWrap: 'wrap' }}
      >
        <Item>
          <Typography variant="h4" gutterBottom>
            <strong>Elevate Your Enterprise</strong>
          </Typography>
          <BodyItem/>
        </Item>
      </Stack>
    </Box>
  );
}
